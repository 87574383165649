import React from 'react';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NumberedListWithImage, VideoWithHeading, TextWithChecklistImg, PlantAndCoffee, FishAndText, LoanGuideConvo } from '@beelineloans/cx-library/src/components/layout/panels';
import { Breakpoint, Paragraph } from '@beelineloans/cx-library/src/components/typography';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';

const RefinanceReduceLoanTerm = () => {
  const headlineBlock = <Breakpoint smallBreakpoint={<>Say ‘adios’ to years of payments.</>} largeBreakpoint={<>Say ‘adios’ to years of payments.</>} />;

  const subheadlineBlock = (
    <>
      Skip the BS application.
      <br />
      Become mortgage-free sooner.
      <br />
      Do it in 15 mins — it’s a no brainer.
    </>
  );

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.LowRatesRefi
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.Radical
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.WhereYouStand
    },
    {
      number: 4,
      noBorder: true,
      ...SiteCopy.numberedList.LoanGuideRefi
    }
  ];

  return (
    <Template
      headline={headlineBlock}
      subHeadline={subheadlineBlock}
      headerProps={{
        options: {
          videoType: 'refi',
          voomly: true
        }
      }}
    >
      <SEO
        isRefi
        path={CONST.LINKS.MAIN.REFI.REDUCETERM}
        canonical={CONST.LINKS.MAIN.REFI.DEFAULT}
        title="Reduce your mortgage term from your sofa"
        description="Say ‘adios’ to years of payments. It’s surprisingly simple."
        antiFlicker
      />
      <NumberedListWithImage sideHeading="Refinance like never before." items={numberedList} cash />
      <VideoWithHeading heading="Cut years of payments without the brain strain." videoSrc={CONST.VIDEOS.VOOMLY.EXPLAINER_REFI} voomly />
      <TextWithChecklistImg heading="Get your Refi-Ready Approval, lickety-split.">
        <Paragraph>
          15 teeny minutes. That’s all it takes to find out how much sooner you&apos;ll own your place. Outright.
          <br />
          <br />
          Wait. What?
          <br />
          <br />
          Yup! Because we collect and verify your actual numbers instantly, we can give you a Refi-Ready Approval with your actual rate — just like that.
          <br />
          <br />
          Heck, you can even do it from your sofa.
        </Paragraph>
      </TextWithChecklistImg>
      <FishAndText />
      <LoanGuideConvo />
      <PlantAndCoffee heading="Imagine the feeling of making that very last payment." buttonTo={CONST.LINKS.MAIN.CALCULATORS.REFINANCE} buttonText="Show me!">
        <Paragraph light fade>
          Play with our refi calculator to see how much sooner you can own your home free and clear.
        </Paragraph>
      </PlantAndCoffee>
    </Template>
  );
};

export default RefinanceReduceLoanTerm;
